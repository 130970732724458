<template>
  <div class="packingList">
    <div class="content">
      <div class="pop-content">
        <div class="queryBoxOne">
          <div class="btnBox">
            <el-button type="primary" @click="add">
              <el-icon>
                <Plus/>
              </el-icon>
              <span class="text">新增</span>
            </el-button>
            <!--        <Alert type="error" class="ml_16" text="基础产品中，该规格，配置有整装/散装 的产品，则该规格产品不能增加箱单关系"/>-->
          </div>
          <div class="queryItem">
            <el-input v-elInput class="w240" @keydown.enter="getPackList" placeholder="名称/简称/简拼/条码"
                      v-model="formData.query" clearable></el-input>
            <div class="queryBtn">
              <el-button type="primary" @click="getPackList"
              >
                <el-icon>
                  <Search/>
                </el-icon
                >
              </el-button>
            </div>
          </div>
        </div>
        <div class="tabList">
          <!-- 列表 -->
          <div class="list" v-if='tableData.length > 0'>
            <div class="list_item" v-for="(item, index) in tableData" :key="index">
              <div class="single">
                <div v-for="(item1, index1) in item" :key="index1" class="item">
                  <div class="upateBox" v-if="index1 != 0">
                    <div class="text">
                      <div class="l">
                        1<span class="upDate-unit">{{ tableData[index][index1 - 1].unitName }}</span>
                        ={{ item1.count }}<span class="upDate-unit">{{ item1.unitName }}</span>
                      </div>
                      <img src="@/assets/img/index/updateImg.png" class="updateImg cur_p"
                           @click="handleUpDate(tableData[index][index1 - 1],item1)"/>
                    </div>
                    <img src="@/assets/img/index/delete.png" class="delImg cur_p"
                         @click="handleDel(tableData[index][index1 - 1],item1)"/>
                  </div>
                  <div class="goodsInfo">
                    <div class="name">
                      【<span class="upDate-unit">{{ item1.unitName }}</span>】{{ item1.productName }}
                    </div>
                    <el-tooltip :content="item1.modelName" placement="bottom" effect="light">
                      <div class="desc">规格型号：{{ item1.modelName }}</div>
                    </el-tooltip>
                    <template v-if='item1.encoded && item1.encoded.length>0'>
                      <el-popover placement="bottom" :width="200" trigger="hover">
                        <template #reference>
                          <div class="desc inline dp_f">
                            <div class="text" v-if='item1.encoded'>条码：{{ item1.encoded[0] }}</div>
                            <el-icon class="icon ">
                              <ArrowDown/>
                            </el-icon>
                          </div>
                        </template>
                        <div class="encodedList">
                          <div class="single" v-for="(i,j) in item1.encoded" :key="j">{{ i }}</div>
                        </div>
                      </el-popover>
                    </template>
                    <div class="desc" v-else>条码: 暂无</div>
                  </div>
                </div>
              </div>
              <img src="@/assets/img/index/delete.png" class="delImg cur_p zh-left-top-fixed"
                   @click="handleDelAll(item)"/>
            </div>

          </div>
          <div class="list zh_empty" v-else></div>
        </div>
        <div class="mainPage">
          <el-pagination
              v-model:current-page="pageInfo.pageNumber"
              layout="total, prev, pager, next , jumper"
              :total="pageInfo.total"
              v-model:page-size='pageInfo.pageSize'
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <!-- 列表 -->
    </div>
    <el-dialog
      v-model="showDia"
      title="箱单关系"
      width="640px"
      class="packingDia"
       :close-on-click-modal="false"
    >
      <div class="content" v-if="updateInfo[0]">
        <div class="after item">
          <div class="title">当前产品【大包装】</div>
          <div class="goodsBox">
            <div class="name">{{updateInfo[0].productName}}</div>
            <div class="unit">单位：{{updateInfo[0].unitName}}</div>
            <el-tooltip :content="updateInfo[0].modelName" placement="bottom" effect="light">
              <div class="desc">规格型号：{{ updateInfo[0].modelName }}</div>
            </el-tooltip>
             <template v-if='updateInfo[0].encoded'>
                <el-popover placement="bottom" :width="200" trigger="hover" >
              <template #reference>
                <div class="desc inline dp_f">
                    <div class="text" >条码：{{updateInfo[0].encoded[0]}}</div><el-icon class="icon "><ArrowDown /></el-icon>
                </div>
              </template>
              <div class="encodedList">
                <div class="single" v-for="(i,j) in updateInfo[0].encoded" :key="j" >
                  {{i}}
                </div>
              </div>
            </el-popover>
            </template>
            <div class="desc" v-else>条码: 暂无</div>
          </div>
        </div>
        <div class="item center">
          <span>1</span>
          <span>=</span>
          <el-input  v-elInput style="width: 56px; margin-left: 10px" v-model="updateInfo[1].count" @blur="updateInfo[1].count = $event.target.value" v-number.fixed maxlength="7"></el-input>
        </div>
        <div class="before item">
          <div class="title">单品【小包装】</div>
             <div class="goodsBox">
            <div class="name">{{updateInfo[1].productName}}</div>
            <div class="unit">单位：{{updateInfo[1].unitName}}</div>
            <el-tooltip :content="updateInfo[1].modelName" placement="bottom" effect="light">
              <div class="desc">规格型号：{{ updateInfo[1].modelName }}</div>
            </el-tooltip>
            <template v-if='updateInfo[1].encoded'>
                <el-popover placement="bottom" :width="200" trigger="hover" >
              <template #reference>
                <div class="desc inline dp_f">
                       <div class="text">条码：{{updateInfo[1].encoded[0]}} </div><el-icon class="icon "><ArrowDown /></el-icon>
                  </div>
              </template>
              <div class="encodedList">
                <div class="single" v-for="(i,j) in updateInfo[1].encoded" :key="j" >
                  {{i}}
                </div>
              </div>
            </el-popover>
            </template>
            <div class="desc" v-else>条码: 暂无</div>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="handleCloseUpDate">取消</el-button>
        <el-button class="main" type="primary" @click="handleOkUpDate">保存</el-button>
      </template>
    </el-dialog>
    <el-dialog
      v-model="addDia"
      title="新增箱单关系"
      width="640px"
      class="packingDia"
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="after item">
          <div class="title">当前产品【大包装】</div>
          <div class="goodsBox">
            <el-input
            v-elInput
              v-if='!addInfo.beforeOne.productId'
              ref="inputBeforeRef"
              placeholder="产品名称/简称/简拼/条码"
              v-model="addInfo.beforeOne.productName"
              @input="getGoodsList(1)"
              class="no_name"
              @keydown.enter="getGoodsList(1)"
              @blur="clearPop(1)"
            ></el-input>
            <div class="zh-selectByAbc no_name" v-else>
              <el-tag
                class="tag1"
                closable
                type="info"
                @close="closeTag(2)"
              >
                {{ addInfo.beforeOne.productName }}
              </el-tag>
            </div>
            <el-popover
              :virtual-ref="inputBeforeRef"
              ref="poperBeforeRef"
              placement="bottom-start"
              :width="600"
              trigger="click"
              popper-class="inputPoper"
              :visible="popShowBefore"
              virtual-triggering
            >
              <el-table :data="goodsList" @row-click="clickGoodsBefore" >
                <el-table-column
                  property="productName"
                  align="center"
                  label="产品名称"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="brandName"
                  align="center"
                  label="产品品牌"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="unitName"
                  align="center"
                  label="产品单位"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="modelName"
                  align="center"
                  label="规格型号"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="model"
                  align="center"
                  label="产品条码"
                  show-overflow-tooltip
                >
                 <template #default="scope">
                  <div class="moreListContent">
                    <more-list :list='scope.row.encoded'></more-list>
                  </div>
              </template>
                </el-table-column>
              </el-table>
            </el-popover>
            <template v-if='addInfo.beforeOne.productId'>
            <div class="unit">单位：{{ addInfo.beforeOne.unitName }}</div>
            <el-tooltip :content="addInfo.beforeOne.modelName" placement="bottom" effect="light">
              <div class="desc">规格型号：{{ addInfo.beforeOne.modelName }}</div>
            </el-tooltip>
            <div class="desc df_p">产品条码：<more-list :list='addInfo.beforeOne.encoded' v-if='addInfo.beforeOne.encoded'></more-list></div>
            </template>
          </div>
        </div>
        <div class="item center">
          <div class="t">
            <img
              src="@/assets/img/packing/change.png"
              class="cur_p changeImg"
              @click="changeAddInfo"
            />
          </div>
          <div class="b">
            <span>1</span>
            <span>=</span>
            <el-input
            v-elInput
              style="width: 56px; margin-left: 10px"
              v-model="addInfo.afterOne.count"
              maxlength="7"
              v-number.fixed
              @blur="addInfo.afterOne.count = $event.target.value"
            ></el-input>
          </div>
        </div>
        <div class="before item">
          <div class="title">单品【小包装】</div>
          <div class="goodsBox">
             <el-input
             v-elInput
              v-if='!addInfo.afterOne.productId'
              ref="inputAfterRef"
              placeholder="产品名称/简称/简拼/条码"
              v-model="addInfo.afterOne.productName"
             @input="getGoodsList(2)"
              class="no_name"
              @keydown.enter="getGoodsList(2)"
              @blur="clearPop(2)"
            ></el-input>
            <div class="zh-selectByAbc no_name" v-else>
              <el-tag
                class="tag1"
                closable
                type="info"
                @close="closeTag(1)"
              >
                {{ addInfo.afterOne.productName }}
              </el-tag>
            </div>
            <el-popover
              :virtual-ref="inputAfterRef"
              ref="poperAfterRef"
              placement="bottom-start"
              :width="600"
              trigger="focus"
              popper-class="inputPoper"
              :visible="popShowAfter"
              virtual-triggering
            >
              <el-table :data="goodsList" @row-click="clickGoodsAfter" v-loading='loading'>
                <el-table-column
                  property="productName"
                  align="center"
                  label="产品名称"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="brandName"
                  align="center"
                  label="产品品牌"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="unitName"
                  align="center"
                  label="产品单位"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="modelName"
                  align="center"
                  label="规格型号"
                  show-overflow-tooltip
                />
                 <el-table-column
                  align="center"
                  label="产品条码"
                >
                 <template #default="scope">
                  <div class="moreListContent">
                    <more-list :list='scope.row.encoded'></more-list>
                  </div>
              </template>
                 </el-table-column>
              </el-table>
            </el-popover>
            <template v-if='addInfo.afterOne.productId'>
             <div class="unit">单位：{{ addInfo.afterOne.unitName }}</div>
            <el-tooltip :content=" addInfo.afterOne.modelName" placement="bottom" effect="light">
              <div class="desc">规格型号：{{  addInfo.afterOne.modelName }}</div>
            </el-tooltip>
             <div class="desc dp_f">产品条码：<more-list :list='addInfo.afterOne.encoded' v-if="addInfo.afterOne.encoded"></more-list></div>
            </template>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="hanldeCloseAdd">取消</el-button>
        <el-button class="main" type="primary" @click="handleOkAdd" :disabled="!isOk"
          >保存</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
import MoreList from '@/components/moreList/moreListshort.vue';
import {computed, getCurrentInstance, onMounted, reactive, ref, toRefs} from "vue";
import {ArrowDown, Plus, Search} from "@element-plus/icons-vue";
import {debounce} from "lodash";
import {useStore} from "vuex";
import {ElMessageBox} from "element-plus";
import {useRoute} from 'vue-router'
import Alert from '@/components/common/Alert/index.vue'
import {
  packingAdd,
  packingAllDel,
  packingDel,
  packingEdit,
  packingList,
  packingProductSearch,
} from "@/utils/api/product/packing.js";

export default {
  name: "customerList",
  components: {
    Plus,
    ArrowDown,
    Search,
    MoreList,
    Alert
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
     const loading = ref(false);
    //关于箱单关系新增
    const addDia = ref(false);
       const pageInfo = reactive({
      pageSize: 10,
      pageNumber: 1,
      total: 0,
    });
    const formData = reactive({
      query:""
    })
    const addInfo = reactive({
      beforeOne: {
        // 当前产品
        productId: "", //产品id
        count: 1, //当前产品数量
        name: "",
        unitName: "", //单位
        unitId: "", //单位
        model: "", //规格
        modelId: "", //规格
        encoded:''
      },
      afterOne: {
        productId: "",
        count: "",
        name: "",
        unitName: "", //单位
        unitId: "", //单位
        modelId: "", //规格
        encoded:""
      },
    });
    let _addInfo = () => {
      return {
        beforeOne: {
          // 当前产品
          productId: "", //产品id
          count: 1, //当前产品数量
          name: "",
          unitName: "", //单位
          unitId: "", //单位
          model: "", //规格
          modelId: "", //规格
          encoded:""
        },
        afterOne: {
          productId: "",
          count: "",
          name: "",
          unitName: "", //单位
          unitId: "", //单位
          modelId: "", //规格
          encoded:""
        },
      };
    };
    const resetAddInfo = () => {
      addInfo.beforeOne = _addInfo().beforeOne;
      addInfo.afterOne = _addInfo().afterOne;
    };
    const add = () => {
      resetAddInfo();
      addDia.value = true;
    };
    //当前产品输入控制poper展示
    const inputAfterRef = ref();
    const poperAfterRef = ref({ hide: () => {} });
    const inputBeforeRef = ref();
    const poperBeforeRef = ref({ hide: () => {} });
    //当前产品Input 失焦 控制poper 隐藏
    const blurAfterInput = () => {
      poperAfterRef.value.hide();
    };
    const blurBeforeInput = () => {
      poperBeforeRef.value.hide();
    };
    //选中产品列表
    const clickGoodsBefore = (row) => {
      addInfo.beforeOne.productId = row.productId;
      addInfo.beforeOne.modelId = row.modelId;
      addInfo.beforeOne.productName = row.productName;
      addInfo.beforeOne.unitName = row.unitName;
      addInfo.beforeOne.modelName = row.modelName;
      addInfo.beforeOne.encoded = row.encoded;
      clearPop(1)
      goodsList.value = [];
    };
    const clickGoodsAfter = (row) => {
      addInfo.afterOne.productId = row.productId;
      addInfo.afterOne.modelId = row.modelId;
      addInfo.afterOne.productName = row.productName;
      addInfo.afterOne.unitName = row.unitName;
      addInfo.afterOne.modelName = row.modelName;
      addInfo.afterOne.encoded = row.encoded;
      clearPop(2)
      goodsList.value = [];
    };
    //切换当前和小包装
    const changeAddInfo = () => {
      let a = Object.assign({}, addInfo.afterOne);
      let b = Object.assign({}, addInfo.beforeOne);
      addInfo.afterOne = b;
      addInfo.afterOne.productId = b.productId
      addInfo.afterOne.modelId = b.modelId
      addInfo.afterOne.count = ''
      addInfo.beforeOne = a;
      addInfo.beforeOne.productId = a.productId;
      addInfo.beforeOne.modelId = a.modelId;
    };

    //确认新增
    const handleOkAdd = async () => {
      if (addInfo.afterOne.productId === addInfo.beforeOne.productId) {
        proxy.$message.error("添加不能选择相同的产品");
        return;
      }
      if (addInfo.afterOne.count === '0') {
        proxy.$message.error('数量不能为0')
        return
      }
      let obj = {
        after:addInfo.afterOne,
        product: addInfo.beforeOne,
      };
      let res = await packingAdd(obj);
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("添加成功");
      addDia.value = false;
      getPackList()
    };
    //取消新增弹窗
    const hanldeCloseAdd = () => {
      ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        addDia.value = false;
      });
    };
    const popShowBefore = ref(false);
    const popShowAfter = ref(false);
    const clearPop = (type)=>{
      //清空tooltips
      let list = document.getElementsByClassName("is-dark");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
      if(type == 1){
        popShowBefore.value = false
      }else{
        popShowAfter.value = false
      }
    }
    //获取产品列表
    const getGoodsList = debounce((num) => {
      let key = num == 1 ? "beforeOne" : "afterOne";
      let key2 = num == 1 ? "afterOne" : "beforeOne";
      if(addInfo[key] && (addInfo[key].productName == ''|| !addInfo[key].productName)){
        return
      }
      loading.value = true;
      packingProductSearch({
        query: addInfo[key].productName,
      }).then((res) => {
        if (res.code != 0) {
          proxy.$message.error(res.msg);
          return;
        }
        let list = res?.data?.list || [];
        list = resetList(list,addInfo[key2].productId);
        goodsList.value = list
      }).finally(() => {
        loading.value = false;
        num == 1 ? (popShowBefore.value = true) : (popShowAfter.value = true);
      });
    }, 200);

    //重置列表
    function resetList(list,key='') {
     return  list.filter((item)=>{
        return (item.productId != key && !item.scattered)
      })
    }
    //产品列表
    const goodsList = ref([]);

    /*
      关于箱单关系列表部分
    */
    const getPackList = () => {
      let params ={
         query:formData.query,
        pageNumber:pageInfo.pageNumber,
        pageSize:pageInfo.pageSize,
      }
      packingList(params).then((res) => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg);
          return;
        }
        let list = []
        if(res.data?.list?.length > 0){
          res.data.list.forEach(item => {
            item = changeList(item).reverse()
            list.push(item)
          });
        }
          tableData.value = list
          pageInfo.total = res.data?.total || 0
      });
    };
    //重组数组
    const changeList = (obj) => {
      let result = [];
      if(obj.child){
        result = result.concat(changeList(obj.child));
      }
      result.push(obj);
      return result;
    };
    onMounted(() => {
      getPackList();
      if (route.query.productId) {
        add()
      } else if (store.state.addPackingInfo?.productId) {
        add()
        store.commit('CLEAR_ADD_PACKING_INFO')
      }
    });

    const handleCurrentChange = (val) => {
      pageInfo.pageNumber = val;
      getPackList();
    };

    /*
     关于箱单关系编辑
    */
    const updateInfo = ref([]);
    const showDia = ref(false); // 控制弹窗是否展示
    //打开编辑弹窗
    const handleUpDate = (item1,item2)=>{
      updateInfo.value[0] =Object.assign({},item1)
      updateInfo.value[1] =Object.assign({},item2)
      showDia.value = true
    }
    //取消编辑弹窗
    const handleCloseUpDate = ()=>{
      ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        showDia.value = false;
      });
    }
    //确认编辑弹窗
    const  handleOkUpDate = async()=>{
      if (updateInfo.value[0].productId === updateInfo.value[1].productId) {
        proxy.$message.error('不能选择相同产品')
        return
      }
      if(updateInfo.value[1].count == '0'){
        proxy.$message.error('数量不能为0')
        return
      }
      let obj = {
        product:updateInfo.value[0],
        after:updateInfo.value[1],
        topProductId:updateInfo.value[0].topProductId,
        topModelId:updateInfo.value[0].topModelId,
      }
      let res = await packingEdit(obj)
      if (res.code === 9121) {
          ElMessageBox.confirm("当前箱单关系已变更,请刷新查看", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "zh-message-box",
          }).then(() => {
              getPackList()
              showDia.value =false
          });
          return;
      }
      if (res.code !== 0) {
          proxy.$message.error(res.msg);
         return;
      }
      proxy.$message.success('编辑成功')
      getPackList()
      showDia.value =false
    }
    //删除箱单关系
    const handleDel = (item1,item2)=>{
      ElMessageBox.confirm("是否确认删除", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
          packingDel({
            productId:item1.productId,
            modelId:item1.modelId,
            afterProductId:item2.productId,
            afterModelId:item2.modelId,
            topProductId:item2.topProductId,
            topModelId:item2.topModelId,
          }).then((res)=>{
            if (res.code !== 0) {
              proxy.$message.error(res.msg);
            return;
           }
           proxy.$message.success('移除成功')
           getPackList()
          })
      });
    }
    //删除整个关系
    const handleDelAll = (item)=>{
      ElMessageBox.confirm("是否确认删除", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        customClass: "zh-message-box",
      }).then(() => {
          packingAllDel({
            topProductId:item[0].topProductId,
            topModelId:item[0].topModelId,
          }).then((res)=>{
            if (res.code !== 0) {
              proxy.$message.error(res.msg);
            return;
           }
           proxy.$message.success('移除成功')
           getPackList()
          })
      });
    }
    const tableData = ref([]);
    const data = reactive({
      countInfo: {
        normal: 8,
        void: 10,
      },
    });
    const isOk = computed(() => {
       return (addInfo.beforeOne.productId && addInfo.afterOne.productId && addInfo.afterOne.count)
    });
    const closeTag = (type,tags)=>{
      if(type == '2'){
        addInfo.beforeOne = {
           productId: "", //产品id
          count: 1, //当前产品数量
          name: "",
          unitName: "", //单位
          unitId: "", //单位
          model: "", //规格
          modelId: "", //规格
          encoded:''
        }
      }else{
        addInfo.afterOne = {
             productId: "",
        count: "",
        name: "",
        unitName: "", //单位
        unitId: "", //单位
        modelId: "", //规格
        encoded:""
        }
      }
    }
    return {
      clearPop,
      closeTag,
      isOk,
      formData,
      handleDelAll,
      handleDel,
      handleOkUpDate,
      handleCloseUpDate,
      updateInfo,
      handleUpDate,
      add,
      addDia,
      ...toRefs(data),
      tableData,
      pageInfo,
      showDia,
      inputAfterRef,
      poperAfterRef,
      blurAfterInput,
      goodsList,
      addInfo,
      getGoodsList,
      inputBeforeRef,
      poperBeforeRef,
      blurBeforeInput,
      clickGoodsBefore,
      clickGoodsAfter,
      changeAddInfo,
      handleOkAdd,
      hanldeCloseAdd,
      getPackList,
      handleCurrentChange,
      loading,
      popShowBefore,
      popShowAfter,
    };
  },
};
</script>

<script setup></script>

<style lang="scss" scoped>
.packingList {
  height: 100%;
  padding:16px;
  box-sizing: border-box;
    >.content{
        border-radius: 16px;
        background: #fff;
        padding: 12px 32px;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;

      .pop-content {
        position: relative;
        height: 100%;
      }
    }
  .tabList {
    margin-top: 34px;
     position: relative;
    height: 80%;
    overflow-y: auto;
    box-sizing: border-box;
    @include overFlow();
    .btn {
      display: inline-block;
      color: $fsColor3;
    }
    .list {
         padding-bottom: 20px;
         .list_item{
            position: relative;
              >.delImg {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
          }
         }
      .single {
        display: flex;
        align-items: center;
        background-color: #f2f4f7;
        padding: 32px 0 32px 32px;
        height: 172px;
        box-sizing: border-box;
        border-radius: $borderRadius8;
        margin-bottom: 20px;
        flex-wrap: nowrap;
        overflow-x: auto;
        position: relative;
        overflow-y: hidden;
        min-width: 100%;
        max-width: max-content;

        &::-webkit-scrollbar {
          width: 2px;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
          border-radius: 2px;
          background: #fafbfc;
        }
        .item {
          display: flex;
          align-items: center;
          .upateBox {
            text-align: center;
            height: 48px;
            line-height: 48px;
            border-radius: $borderRadius8;
            background-color: #fff;
            position: relative;
            font-size: 18px;
            margin-right: 34px;
            padding: 0 16px;
            width: max-content;
            .text{
              display: flex;
               align-items: center;
            justify-content: center;
              .l{
                //@include textOverflow(100px);
              }

            }
            .delImg {
              width: 16px;
              height: 16px;
              position: absolute;
              top: -8px;
              right: -8px;
            }
            .updateImg {
              width: 14px;
              height: 14px;
              margin-left: 8px;
            }
          }
          .goodsInfo {
             padding: 24px;
             background-color: #f2f4f7;
            white-space: pre-wrap;
            word-wrap: break-word;
            width: 180px;
            box-sizing: border-box;
            border-radius: 8px;
            .name {
              margin-bottom: 24px;
              width: 150px;
              font-size: 16px;
              line-height: 1.2;
              @include textEllipsis(2);
            }

            .desc {
              margin-bottom: 14px;
              color: #737480;
              width: 120px;
              @include textOverflow(130px);
              line-height: 1.3;
              cursor: pointer;
                .df_p{
            @include textOverflow(130px);
            display: flex;
            flex-wrap: nowrap
          }
              .text{
                @include textOverflow(110px);
                display: inline-block;
              }
              .icon{
                position: relative;
                top: 2px;
                right: -2px;
              }
            }
          }
        }
      }
    }
  }
}
   .upDate-unit{
              display: inline-block;
     //line-height: 1;
     ////@include textOverflow(30px);
     //position: relative;
     //top: 2px;
            }
.packingDia {
  .content {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      flex: 1;
      .title {
        padding-left: 6px;
        line-height: 1;
        border-left: 4px solid $fsColor3;
        color: $fsColor;
        font-size: 16px;
        font-weight: 500;
      }
      &.center {
        text-align: center;
        font-size: 20px;
        color: $fsColor;
        .changeImg {
          width: 72px;
          height: 36px;
          margin-bottom: 16px;
        }
      }
      .goodsBox {
        padding: 22px;
        margin-top: 16px;
        border-radius: 16px;
        background-color: #fafbfc;
        padding-bottom: 6px;
        height: 172px;
        box-sizing: border-box;
        .name {
          margin-bottom: 16px;
          width: 180px;
          line-height: 36px;
          font-size: 16px;
          @include  textOverflow(140px);
          line-height: 1.5;
          color: $fsColor;
        }
        >.desc {
          margin-bottom: 14px;
          color: #737480;
          width: 140px;
          @include textOverflow(140px);
          line-height: 1.5;
          cursor: pointer;
          &.df_p{
            @include textOverflow(140px);
            display: flex;
            flex-wrap: nowrap;
          }
          .text{
            @include textOverflow(110px);
            display: inline-block;
          }
          .icon{
            position: relative;
            top: 2px;
            right: -4px;
          }
        }
        .no_name {
          margin-bottom: 24px;
          width: 184px;
        }
        .unit {
          color: $fsColor;
          line-height: 1.5;
          margin-bottom: 14px;
          @include textOverflow(140px);
        }
      }
    }
  }
  .zh-selectByAbc{
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: $borderRadius8;
  padding: 6px 10px;
  box-sizing: border-box;
  width: 166px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
  &:hover{
    box-shadow: 0 0 0 1px var(--el-text-color-disabled) inset;
    transition: 0.2s all linear;
  }
  .tag1{
    height: 28px;
    :deep(.el-tag__content){
      @include textOverflow(120px);
      color: #161829;
      line-height: 28px;
    }
  }
}
}
</style>
